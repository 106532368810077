<template>
  <CRow>
    <CCol col="10" md="10">
      <CCard>
        <CCardHeader class="mt-2">
          <h5>
            {{ $t("PAYMENT_LINKS.CREATE_HEADER") }}
          </h5>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12">
              <div id="amount-input">
                <div class="label-header-text">
                  {{ $t("PAYMENT_LINKS.AMOUNT") }}
                </div>
                <CInput
                  prepend="Rp"
                  class="amount-input"
                  :is-valid="
                    isSubmitClicked
                      ? parseInt($store.getters.getAmount, 10) > 0
                      : null
                  "
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  v-model="amount"
                  :lazy="0"
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12">
              <div class="label-header-text">
                {{ $t("PAYMENT_LINKS.EXPIRY") }}
                <img
                  :title="$t('PAYMENT_LINKS.EXPIRY_DAYS_MESSAGE')"
                  src="../../assets/img/blue-circle.svg"
                />
              </div>
              <step-input />
            </CCol>
          </CRow>
          <customer-details :isSubmitClicked="isSubmitClicked" />
          <CRow>
            <CCol tag="label" sm="3" md="3" class="label-header-text">
              <CSwitch
                class="mr-1"
                color="info"
                shape="pill"
                variant="3d"
                @update:checked="addItems = !addItems"
              />
              {{ $t("PAYMENT_LINKS.LABELS.ITEMS") }}
            </CCol>
            <CCol col="12">
              <item-list v-if="addItems" :isSubmitClicked="isSubmitClicked" />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12">
              <div class="label-header-text">
                {{ $t("PAYMENT_LINKS.TABLE.NOTES") }}
              </div>
              <textarea
                class="col-12 notes-text"
                :placeholder="getNotesPlaceholder"
                @input="(event) => updateNotes(event.target.value)"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CButton
        color="durianprimary"
        class="float-right"
        @click="createNewPaymentLink"
      >
        <strong>
          {{ $t("PAYMENT_LINKS.CREATE") }}
        </strong>
      </CButton>
      <CButton class="float-right" @click="cancelCreate">
        <strong>
          {{ $t("COMMON.CANCEL") }}
        </strong>
      </CButton>
      <new-payment-link-modal
        :isModalOpen="isModalOpen"
        :generatedPaymentLink="generatedLink"
        :toggleModal="toggleModal"
        :orderID="orderID"
      />
    </CCol>
  </CRow>
</template>

<script>
import * as types from "@/store/mutation-types.js";
import { createPaymentLink } from "@/api/payment_links.api.js";
import StepInput from "./components/StepInput";
import CustomerDetails from "./components/CustomerDetails";
import ItemList from "./components/ItemList.vue";
import { paymentLink } from "@/../config.js";
import NewPaymentLinkModal from "./components/NewPaymentLinkModal.vue";
import { bulkUpload } from "@/api/upload.api";

const PAYMENT_LINK_PREFIX = paymentLink.url.toString();

const IDR = "IDR";
export default {
  name: "NewPaymentLink",
  components: {
    StepInput,
    CustomerDetails,
    ItemList,
    NewPaymentLinkModal,
  },
  data() {
    return {
      addItems: false,
      generatedLink: null,
      isModalOpen: false,
      orderID: null,
      isSubmitClicked: false,
    };
  },
  methods: {
    cancelCreate() {
      this.goTo("/payment_links");
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
      if (!this.isModalOpen) {
        this.generatedLink = null;
        this.isSubmitClicked = false;
      }
    },
    updateNotes(value) {
      this.$store.commit(types.setNotes, value);
    },
    calculateExpiryDate(expiryDays) {
      const expiryDate = new Date(
        new Date().getTime() +
          expiryDays * 24 * 60 * 60 * 1000 -
          new Date().getTimezoneOffset() * 60000
      ).toISOString();
      return expiryDate;
    },
    isItemsValid() {
      const items = this.getItems;
      const length = items.length;
      for (let index = 0; index < length; index++) {
        if (
          items[index].name.length === 0 ||
          items[index].qty <= 0 ||
          parseInt(items[index].price, 10) <= 0
        ) {
          return false;
        }
      }
      return true;
    },
    validatePayload(payload) {
      const { amount, customer } = payload;
      if (amount.length === 0) {
        this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.EMPTY_AMOUNT"));
        return false;
      } else if (parseInt(amount, 10) === 0) {
        this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.ZERO_AMOUNT"));
        return false;
      } else if (customer.email.length === 0) {
        this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.EMAIL_MISSING"));
        return false;
      } else if (!this.validateEmail(customer.email)) {
        this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.INVALID_EMAIL"));
        return false;
      } else if (
        customer.mobile.length === 0 ||
        !this.validatePhone(customer.mobile)
      ) {
        this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.INVALID_PHONE"));
        return false;
      } else if (customer.given_name.length === 0) {
        this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.NAME_MISSING"));
        return false;
      } else if (!this.isItemsValid()) {
        this.showToaster(
          this.$t("PAYMENT_LINKS.ERRORS.ITEMS_MISSING_MANDATORY_FEILDS")
        );
        return false;
      }
      return true;
    },
    async createNewPaymentLink() {
      const {
        getAmount,
        getExpiryDays,
        getCustomerAddress,
        getCustomerCity,
        getCustomerEmail,
        getCustomerName,
        getCustomerPhoneNumber,
        getCustomerPostCode,
        getCustomerState,
        getItems,
        getNotes,
      } = this.$store.getters;
      const expiryDate = this.calculateExpiryDate(getExpiryDays);
      const request = {
        amount: getAmount,
        currency: IDR,
        customer: {
          address_line_1: getCustomerAddress,
          city: getCustomerCity,
          email: getCustomerEmail,
          given_name: getCustomerName,
          mobile: getCustomerPhoneNumber,
          postal_code: getCustomerPostCode,
          region: getCustomerState,
        },
        expiry_date: expiryDate,
        is_payment_link: true,
        items: getItems,
        description: getNotes,
      };
      this.isSubmitClicked = true;
      if (!this.validatePayload(request)) {
        return;
      }
      if (request.items.length > 0) {
        const response = await this.uploadLogoFiles();
        if (response) {
          response.forEach((resp) => {
            request.items.forEach((item) => {
              if (item.file_name === resp.file_name) {
                item.logo = resp.file_path;
              }
            });
          });
        }
      }
      try {
        const response = await createPaymentLink(request);
        this.generatedLink =
          PAYMENT_LINK_PREFIX + response.data.data.payment_link_url;
        this.orderID = response.data.data.id;
        this.toggleModal();
      } catch (error) {
        this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.CREATE_ERROR"));
      }
    },
    async uploadLogoFiles() {
      const fileMap = this.createFileMap();
      if (fileMap.size !== 0) {
        try {
          const response = await bulkUpload(
            this.createFileUploadPayload(fileMap)
          );
          return response.data.data;
        } catch (error) {
          this.showToaster(this.$t("PAYMENT_LINKS.ERRORS.CREATE_ERROR"));
        }
      }
    },
    createFileUploadPayload(fileMap) {
      const bodyFormData = new FormData();
      for (let [key] of fileMap) {
        bodyFormData.append("files", fileMap.get(key));
      }
      return bodyFormData;
    },
    createFileMap() {
      let fileMap = new Map();
      this.getItems.forEach((item) => {
        if (item.file !== "") {
          fileMap.set(item.name, item.file);
        }
      });
      return fileMap;
    },
    resetStore() {
      this.$store.commit(types.resetPaymetLinkState);
    },
  },
  computed: {
    getNotesPlaceholder() {
      return this.$t("PAYMENT_LINKS.EXAMPLE_TEXT");
    },
    getItems() {
      return this.$store.getters.getItems;
    },
    amount: {
      get() {
        return this.formatAmount(this.$store.getters.getAmount);
      },
      set(value) {
        const amount = this.removeDotsFromAmount(value);
        this.$store.commit(types.setAmount, amount);
      },
    },
  },
  created() {
    this.resetStore()
  }
};
</script>

<style scoped>
.amount-input {
  width: 30%;
}
.label-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 8px;
  padding-top: 25px;
  color: #4e4f52;
}
.details-column {
  padding-left: 5%;
  padding-right: 4%;
  border-right: 1px solid #efefef;
}
.customer-details-header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #4e4f52;
  margin-top: 8%;
  margin-bottom: 1%;
}
textarea {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  height: 100px;
  padding-left: 1.5%;
  padding-top: 1.5%;
  font-size: 14px;
}
textarea:focus {
  outline-width: 0;
}
.label-header-text {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 2px;
}
</style>