import { httpClient } from './httpClient.js';

const
    LISTPRODUCT = '/dashboard/products/',
    DELETEPRODUCT = "dashboard/products/";
    
const   
    listProduct = (searchParams) => httpClient.dashboardService.get(LISTPRODUCT, { "params": searchParams }),
    deleteProduct = (id) => httpClient.dashboardService.delete(DELETEPRODUCT + id);

export {
    listProduct,
    deleteProduct,
}