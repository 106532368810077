<template>
  <CModal
    title="Congratulations!"
    color="success"
    :show="isModalOpen"
    :centered="true"
    body-wrapper="modal-body"
    header-wrapper="modal-header"
  >
    <p style="font-weight: bold">{{ $t("PAYMENT_LINKS.CREATED") }}</p>
    <img
      src="../../../assets/img/payments_link.png"
      height="200px"
    />
    <p>{{ $t("PAYMENT_LINKS.SHARE") }}</p>
    <CInput
      type="text"
      class="col-12"
      readonly="readonly"
      id="generatedPaymentLinkInput"
      :value="generatedPaymentLink"
    >
      <template #append>
        <CButton
          type="Copy"
          color="durianprimary"
          @click.prevent="copyToClipboard(generatedPaymentLink, 'Link Copied!')"
        >
          Copy
        </CButton>
      </template>
    </CInput>
    <div slot="footer">
      <CButton
        type="Copy"
        color="durianprimary"
        @click="goTo('/payment_links/detail/', orderID)"
      >
        {{ $t("PAYMENT_LINKS.VIEW_DETAILS") }}
      </CButton>
      &nbsp;
      <CButton type="Copy" color="durianprimary" @click="createNew">
        {{ $t("PAYMENT_LINKS.CREATE_HEADER") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import * as types from "@/store/mutation-types.js";

export default {
  name: "NewPaymentLinkModal",
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    generatedPaymentLink: String,
    orderID: String,
  },
  methods: {
    createNew() {
      this.toggleModal();
      this.$store.commit(types.resetPaymetLinkState);
      location.reload();
    },
  },
};
</script>

<style>
.modal-body {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.modal-header h5 {
  margin-left: auto;
  color: white;
}
</style>
