<template>
  <CRow>
    <CContainer class="mx-3 p-0 item-list">
      <CRow>
        <CContainer class="p-2 mx-3">
          <CRow class="padded" v-if="getItems.length !== 0">
            <CCol sm="2"
              ><label>{{
                $t("PAYMENT_LINKS.NEW_PAYMENT_LINK.NAME")
              }}</label></CCol
            >
            <CCol sm="2"
              ><label>{{
                $t("PAYMENT_LINKS.NEW_PAYMENT_LINK.QUANTITY")
              }}</label>
            </CCol>
            <CCol sm="2"
              ><label>{{
                $t("PAYMENT_LINKS.NEW_PAYMENT_LINK.PRICE")
              }}</label></CCol
            >
            <CCol sm="2"
              ><label>{{
                $t("PAYMENT_LINKS.NEW_PAYMENT_LINK.TOTAL_PRICE")
              }}</label></CCol
            >
            <CCol sm="2">
              <label> {{ $t("PAYMENT_LINKS.ITEMS_TABLE.LOGO_URL") }} </label>
              <span class="optional">{{
                $t("PAYMENT_LINKS.ITEMS_TABLE.OPTIONAL")
              }}</span>
            </CCol>
          </CRow>
          <div v-for="(item, index) in getItems" :key="index" class="padded">
            <CRow>
              <CCol>
                <multiselect
                  class="custom_multiselect"
                  v-model="item.value"
                  :placeholder="$t('PAYMENT_LINKS.ITEMS_TABLE.PLACEHOLDER_NAME')"
                  :is-valid="isSubmitClicked ? getItems[index].name > 0 :null"
                  :loading="isLoading"
                  :internal-search="false"
                  @search-change="getProductList($event, index)"
                  :searchable="true"
                  :allow-empty="false"
                  :hide-selected="false"
                  @close="clear"
                  @select="selectProduct($event, index)"
                  label="name"
                  track-by="id"
                  :options="productOptions"
                  :option-height="104"
                  :show-labels="false"
                >
		  <span slot="noResult">No items found</span>
		  <span slot="noOptions">Type something</span>
                  <template slot="option" slot-scope="props"
                    ><img
                      class="option__image"
                      :src="props.option.logo"
                      @error="
                        $event.target.src = require(`./../../../../src/assets/img/blank.svg`)
                      "
                      width="50"
                      height="50"
                    />
                    <span
                      class="option__title"
                      v-if="props.option.name"
                      style="padding-left: 30px"
                      >{{ props.option.name
                      }}<span v-if="props.option.price"
                        >{{$t("COMMON.CURRENCY_CODE")}} {{ formatAmount(props.option.price) }}</span
                      ></span
                    >
                    <button
                      type="button"
                      @click.stop="
                        deleteProduct(props.option.id, props.option.name)
                      "
                      class="close"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </template>
                </multiselect>
              </CCol>
              <CCol>
                <CInput
                  type="number"
                  min="0"
                  step="1"
                  name="item_quantity"
                  :value="getItems[index].qty"
                  :is-valid="isSubmitClicked ? getItems[index].qty > 0 :null"
                  @input="(value) => setItemQuantity(value, index)"
                >
                </CInput>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  name="item_price"
                  :is-valid="isSubmitClicked ? parseInt(getItems[index].price, 10) > 0 : null"
                  @input="(value) => setItemPrice(value, index)"
                  :value="formatAmount(getItems[index].price)"
                >
                </CInput>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  disabled
                  name="item_total_price"
                  :value="formatAmount(setTotalItemPrice(index))"
                >
                </CInput>
              </CCol>
              <CCol>
                <CButton
                  v-if="item.logo"
                  size="sm"
                  color="lightblueborder"
                  @click="showPreview(item.name, item.logo, index)"
                >
                  {{$t("PAYMENT_LINKS.NEW_PAYMENT_LINK.VIEW_IMAGE")}}</CButton
                >
                <CButton
                  v-else
                  size="sm"
                  color="lightblueborder"
                  @click="$refs.file[index].click()"
                  >{{$t("PAYMENT_LINKS.NEW_PAYMENT_LINK.UPLOAD_IMAGE")}}</CButton
                >
                <input
                  type="file"
                  ref="file"
                  style="display: none"
                  @change="addFile($event.target.files, index)"
                />

              </CCol>
              <CCol>
                <CButton
                  size="sm"
                  style="width: 100px"
                  color="lightblueborder"
                  @click="decrementItems(index)"
                >
                  {{$t("PAYMENT_LINKS.NEW_PAYMENT_LINK.DELETE")}}
                </CButton>
              </CCol>
            </CRow>
          </div>
          <CButton
            size="sm"
            style="width: 200px; margin-left: 25px"
            class="padded"
            color="lightblueborder"
            @click.prevent="incrementItems(item)"
            >{{$t("PAYMENT_LINKS.NEW_PAYMENT_LINK.ADD")}}</CButton
          >
        </CContainer>
      </CRow>
    </CContainer>
    <CModal
      size="sm"
      color="light"
      class="custom_modal"
      :title.sync="previewModalTitle"
      :show.sync="showPreviewModal"
      :centered="true"
    >
      <img
        id="previewImg"
        ref="previewImg"
        alt="your image"
        width="100"
        height="100"
        @error="
          $event.target.src = require(`./../../../../src/assets/img/blank.svg`)
        "
      />
      <div slot="footer">
        <CButton color="" @click="replaceFile(previewModalIndex)">
          {{$t("COMMON.REPLACE")}}
        </CButton>
        &nbsp;
        <CButton color="durianprimary" @click="showPreviewModal = false">
          {{$t("COMMON.OK")}}
        </CButton>
      </div>
    </CModal>
  </CRow>
</template>
<script>
import * as types from "@/store/mutation-types.js";
import Multiselect from "vue-multiselect";
import { listProduct, deleteProduct } from "@/api/product.api";

export default {
  name: "ItemList",
  components: {
    Multiselect,
  },
  props: { isSubmitClicked: Boolean },
  data() {
    return {
      itemCount: 1,
      showPreviewModal: false,
      previewModalTitle: "",
      previewModalIndex: null,
      productOptions: [],
      isLoading: false,
    };
  },
  methods: {
    commitToStore(item, index) {
      const request = { index: index, value: item };
      this.$store.commit(types.setItems, request);
    },
    setItemName(value, index) {
      const item = this.getItems;
      item[index].name = value;
      this.commitToStore(item[index], index);
    },
    setItemPrice(value, index) {
      const item = this.getItems;
      value = this.removeDotsFromAmount(value);
      item[index].price = value;
      this.commitToStore(item[index], index);
    },
    setItemQuantity(value, index) {
      const item = this.getItems;
      item[index].qty = parseInt(value, 10);
      this.commitToStore(item[index], index);
    },
    setItemID(value, index) {
      const item = this.getItems;
      item[index].id = value;
      this.commitToStore(item[index], index);
    },
    setLogoUrl(value, index) {
      const item = this.getItems;
      item[index].logo = value;
      this.commitToStore(item[index], index);
    },
    setTotalItemPrice(index) {
      const item = this.getItems;
      let totalprice = item[index].qty * item[index].price;
      return totalprice;
    },
    setFile(value, index) {
      const item = this.getItems;
      item[index].file = value;
      item[index].file_name = value.name;
      this.commitToStore(item[index], index);
    },
    setItemValue(value, index) {
      const item = this.getItems;
      item[index].value = value;
      this.commitToStore(item[index], index);
    },
    incrementItems() {
      this.itemCount = this.itemCount + 1;
      const item = {
        name: "",
        qty: "",
        price: "",
        logo: "",
        file: "",
        file_name: "",
      };
      this.$store.commit(types.addItem, item);
    },
    decrementItems(index) {
      if (this.itemCount > 1) this.itemCount--;
      this.$store.commit(types.removeItem, index);
    },
    validateItemQuantity(itemQuantity) {
      return !(
        itemQuantity !== null &&
        (itemQuantity.length === 0 || parseInt(itemQuantity, 10) <= 0)
      );
    },
    addFile(files, i) {
      let self = this;
      let file = files[0];
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        self.showToaster(self.$t("COMMON.FILE_FORMAT"));
        return;
      }
      self.setFile(file, i);
      self.setLogoUrl(window.URL.createObjectURL(file), i);
    },
    showPreview(name, logo, index) {
      this.$refs.previewImg.src = logo;
      this.previewModalTitle = name;
      this.previewModalIndex = index;
      this.showPreviewModal = true;
    },
    replaceFile(index) {
      this.showPreviewModal = false;
      this.$refs.file[index].click();
    },
    clear() {
      this.productOptions = [];
    },
    selectProduct(item, i) {
      if (item.price !== "") {
        this.setItemPrice(this.formatAmount(item.price), i);
      }
      if (item.logo !== "") {
        this.setLogoUrl(item.logo, i);
      }

      this.setItemName(item.name, i);
      this.setItemQuantity(1, i);
      this.setItemID(item.id, i);
      this.setItemValue(item, i);
    },
    addNewitem(search, index) {
      let value = {
        name: search,
      };
      
      this.setItemValue(value, index);
      this.setItemName(search, index);
    },
    getSearchParam(search) {
      let paramsMap = new Map();
      paramsMap["name"] = search;
      return paramsMap
    },
    getProductList(query, index) {
      if (query !== "") {
        this.addNewitem(query, index)
        this.isLoading = true;
        listProduct(this.getSearchParam(query))
          .then((response) => {
            const err = this.verifyHttpResponse(response);
            if (err === false) {
              this.productOptions = [];
              response.data.data.forEach((element) => {
                let product = {
                  id: element.id,
                  logo: element.logo,
                  name: element.name,
                  price: element.price,
                };
                this.productOptions.push(product);
              });
              this.isLoading = false;
            }
          })
          .catch((e)=>this.handlerAPIError(e));
      }
    },
    deleteProduct(id, name) {
      let i = this.productOptions.findIndex((el) => (el.name = name));
      if (i !== -1) {
        this.productOptions.splice(i, 1);
      }
      if (id !== "") {
        deleteProduct(id)
          .then((response) => {
            const err = this.verifyHttpResponse(response);
            if (err === false) {
              this.showToaster(self.$t("COMMON.DELETED"));
            }
          })
          .catch((e)=>this.handlerAPIError(e));
      }
    },
    checkValidity() {
      return this.isSubmitClicked;
    }
  },
  computed: {
    invalidItemNameFeedback() {
      return "Invalid item name";
    },
    invalidItemQuantityFeedback() {
      return "Invalid item quantity";
    },
    invalidItemPriceFeedback() {
      return "Invalid item price";
    },
    getItems() {
      return this.$store.getters.getItems;
    },
  },
  watch: {
    isSubmitClicked: function () {
      this.checkValidity();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.item-add-remove-button {
  border-radius: 50%;
  background: transparent;
  height: 2rem;
  width: 2rem;
  border-color: #4e4f52;
}
.item-list {
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(213, 213, 213, 0.25);
  border-radius: 4px;
  border-right: 1px solid #efefef;
  margin-top: 3%;
}
.padded {
  padding-left: 2%;
  padding-top: 8px;
}
label {
  font-weight: bold;
}
.add-item-button {
  padding-bottom: 10px;
}
.btn-lightblueborder {
  height: 35px;
  width: 100%;
  color: #725cf6;
  background-color: #ffffff;
  border-color: #725cf6;
  font-family: Apercu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #725cf6;
  border-radius: 4px;
}

.optional {
  color: #818386;
  padding-left: 10px;
  font-family: Apercu Pro;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

input:invalid {
  border-color: #e55353;
}
</style>

<style lang="css" scoped>
.custom_multiselect >>> .multiselect__option--highlight {
  background: #ffffff !important;
  color: #4e4f52 !important;
}

.custom_multiselect >>> .multiselect__option--highlight:hover {
  color: #4e4f52 !important;
  background-color: #f1f1f1 !important;
  border-color: #818386 !important;
}

.custom_multiselect >>> .multiselect__content-wrapper {
  width: 160% !important;
}
.custom_multiselect >>> .option__title {
  display: inline-block;
  box-sizing: border-box;
  white-space: pre-line;
  word-wrap: break-word;
}

.custom_multiselect >>> .option__title span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.custom_multiselect >>> .option__title .maxWidth {
  width: 100px;
}

.custom_multiselect >>> .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
}

.custom_multiselect >>> .multiselect__input:invalid {
  border-color: #e55353;
}

.custom_multiselect >>>  .multiselect__tags {
  padding: 8px 38px 0 8px;
}

.custom_modal >>> .modal-header h5 {
    color: black !important;
}
</style>
