<template>
  <div class="customer-details-div">
    <CRow>
      <CCol col="5" class="details-column">
        <div class="customer-details-header">
          {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_DETAILS") }}
        </div>
        <div class="label-text">
          {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_NAME") }}
        </div>
        <input
          class="col-12"
          :required="checkValidity()"
          @change="
            (event) => updateCustomerFeilds(event.target.value, setCustomerName)
          "
        />
        <br />
        <div class="label-text">
          {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_EMAIL") }}
        </div>
        <input
          class="col-12"
          type="email"
          :required="checkValidity()"
          @change="
            (event) =>
              updateCustomerFeilds(event.target.value, setCustomerEmail)
          "
        />
        <br />
        <div class="label-text">
          {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_PHONE") }}
        </div>
        <div>
          <span class="phone-prefix"> +62 </span>
          <input
            class="col-10 phone-input"
            :required="checkValidity()"
            type="tel"
            pattern="8\d{9,11}"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            @change="
              (event) =>
                updateCustomerFeilds(event.target.value, setCustomerPhoneNumber)
            "
          />
        </div>
      </CCol>
      <CCol col="7" class="address-column">
        <div class="customer-address-header">
          {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_ADDRESS") }}
        </div>
        <div class="label-text">
          {{ $t("PAYMENT_LINKS.LABELS.ADDRESS") }}
        </div>
        <input
          class="col-12"
          @change="
            (event) =>
              updateCustomerFeilds(event.target.value, setCustomerAddress)
          "
        />
        <br />
        <div class="label-text">
          {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_ADDRESS_CITY") }}
        </div>
        <input
          class="col-12"
          @change="
            (event) => updateCustomerFeilds(event.target.value, setCustomerCity)
          "
        />
        <br />
        <div>
          <div class="label-text inline-block">
            {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_ADDRESS_STATE") }}
          </div>
          <div class="label-text inline-block with-left-margin">
            {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_ADDRESS_ZIP") }}
          </div>
        </div>
        <input
          class="col-12 half-width"
          @change="
            (event) =>
              updateCustomerFeilds(event.target.value, setCustomerState)
          "
        />
        <input
          class="col-12 half-width with-padding"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="5"
          @change="
            (event) =>
              updateCustomerFeilds(event.target.value, setCustomerPostCode)
          "
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import * as types from "@/store/mutation-types.js";
export default {
  name: "CustomerDetails",
  props: { isSubmitClicked: Boolean },
  data() {
    return {
      setCustomerName: types.setCustomerName,
      setCustomerEmail: types.setCustomerEmail,
      setCustomerPhoneNumber: types.setCustomerPhoneNumber,
      setCustomerAddress: types.setCustomerAddress,
      setCustomerCity: types.setCustomerCity,
      setCustomerState: types.setCustomerState,
      setCustomerPostCode: types.setCustomerPostCode,
    };
  },
  methods: {
    updateCustomerFeilds(value, feild) {
      this.$store.commit(feild, value);
    },
    checkValidity() {
      return this.isSubmitClicked;
    },
  },
  watch: {
    isSubmitClicked: function () {
      this.checkValidity();
    },
  },
};
</script>

<style scoped>
.details-column {
  padding-left: 5%;
  padding-right: 4%;
  border-right: 1px solid #efefef;
}
.customer-details-header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #4e4f52;
  margin-top: 8%;
  margin-bottom: 1%;
}
.phone-prefix {
  background: grey;
  padding-left: 3.4%;
  padding-right: 3.4%;
  padding-top: 9px;
  padding-bottom: 9px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #818386;
  background: #ebedef;
  border-left: 1px solid #d8dbe0;
  border-top: 1px solid #d8dbe0;
  border-bottom: 1px solid #d8dbe0;
}
.phone-input {
  margin-bottom: 13%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.address-column {
  padding-right: 5%;
  padding-left: 4%;
}
.customer-details-div {
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(213, 213, 213, 0.25);
  border-radius: 4px;
  border-right: 1px solid #efefef;
  margin-top: 3%;
}
input {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  height: 36px;
  padding-left: 2%;
  font-size: 14px;
}
input:focus {
  outline-width: 0;
}
input:invalid {
  border-color: #e55353;
}
.label-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 8px;
  padding-top: 25px;
  color: #4e4f52;
}
.customer-address-header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #4e4f52;
  margin-top: 5%;
  margin-bottom: 1%;
}
.inline-block {
  display: inline-block;
}
.with-left-margin {
  margin-left: 41%;
}
.half-width {
  display: inline-block;
  width: 47%;
}
.with-padding {
  margin-left: 5.5%;
}
.prepend-code {
  display: inline-block;
  position: relative;
  bottom: 20.5%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
</style>