
import { httpClient } from './httpClient.js';

const
    BULK_UPLOAD = '/dashboard/bulkupload';

let config = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }

const bulkUpload = async (formdata) =>  { return await httpClient.dashboardService.post(BULK_UPLOAD, formdata, config)} ;

export {
    bulkUpload
}