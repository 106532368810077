<template>
  <span>
    <div
      class="controller pointer-on-hover border-radius-minus"
      @click="updateExpiryDays('step-down')"
      :style="dynamicStepDownColor"
    >
      <div class="text">-</div>
    </div>
    <input
      v-model="expiryDays"
      maxlength="2"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
    <div
      class="controller pointer-on-hover border-radius-plus"
      :style="dynamicStepUpColor"
      @click="updateExpiryDays('step-up')"
    >
      <div class="text">+</div>
    </div>
  </span>
</template>
<script>
import * as types from "@/store/mutation-types.js";
const UP = "step-up";
const DOWN = "step-down";
export default {
  name: "StepInput",
  methods: {
    updateExpiryDays(type) {
      let days = this.$store.getters.getExpiryDays;
      if (days === 1 && type === DOWN) {
        return;
      }
      if (days === 31 && type === UP) {
        return;
      }
      type === UP ? days++ : days--;
      this.$store.commit(types.setExpiryDays, days);
    },
  },
  computed: {
    expiryDays: {
      get() {
        return this.$store.getters.getExpiryDays;
      },
      set(value) {
        if (value.length === 0) {
          return;
        }
        value = parseInt(value, 10);
        let days = this.$store.getters.getExpiryDays;
        if (value > 31) {
          days = 31;
        } else if (value <= 0) {
          days = 1;
        } else {
          days = value;
        }
        this.$store.commit(types.setExpiryDays, days);
      },
    },
    disableStepButtonsStyle() {
      return {
        background: "#ebedef",
        color: "#818386",
      };
    },
    dynamicStepUpColor() {
      if (this.$store.getters.getExpiryDays >= 31) {
        return this.disableStepButtonsStyle;
      }
    },
    dynamicStepDownColor() {
      if (this.$store.getters.getExpiryDays <= 1) {
        return this.disableStepButtonsStyle;
      }
    },
  },
};
</script>
<style scoped>
.controller {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #725cf6;
  color: white;
}
.text {
  position: relative;
  top: 7px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  align-items: center;
  text-align: center;
}
input {
  position: relative;
  top: 6.4px;
  width: 36px;
  background: #ffffff;
  border: 1px solid #d8dbe0;
  height: 36px;
  text-align: center;
  font-size: 14px;
}
input:focus {
  outline-width: 0;
}
.border-radius-minus {
  border-radius: 4px 0px 0px 4px;
}
.border-radius-plus {
  border-radius: 0px 4px 4px 0px;
}
</style>